module.exports = {
  //HOME
  '/': '/',

  //SERVICES
  '/servicios/expert-guidance/': '/services-data-strategy/',
  '/servicios/data-preparation/': '/services-data-analytics/',
  '/servicios/data-architecture/': '/services-data-architecture/',
  '/servicios/business-intelligence/': '/services-data-visualization/',
  '/servicios/artificial-intelligence/': '/services-artificial-intelligence/',

  //SOLUTIONS
  //Solutions - Por sector
  '/soluciones/industry/': '/data-solutions-industry/',
  '/soluciones/retail/': '/data-solutions-retail/',
  '/soluciones/insurance/': '/data-solutions-insurance/',
  '/soluciones/hospitality/': '/data-solutions-hospitality/',
  '/soluciones/admin/': '/data-solutions-administration/',
  //Solutions - Por tipología
  '/soluciones/strategy/': '/data-solutions-strategy/',
  '/soluciones/machine-learning/': '/solutions-machine-learning/',
  '/soluciones/business-intelligence/': '/data-solutions-bi/',
  '/soluciones/infrastructure/': '/data-solutions-infrastructure/',

  //PLATFORM
  '/plataforma/intelek-landing/': '/data-analytics-platform/',
  '/plataforma/collector/': '/data-integration-software/',
  '/plataforma/compute/': '/data-processing-software/',
  '/plataforma/analytics/': '/business-intelligence-software/',
  '/plataforma/recommender/': '/soluciones-de-analisis-de-ventas/',
  '/plataforma/data-governance/': '/platform-data-gobernance/',
  '/plataforma/data-manager/': '/platform-data-management/',
  '/plataforma/data-api/': '/plataforma-data-api/',
  '/plataforma/start-trial/': '/intelek-free-trial/',
  '/plataforma/book-demo-form/': '/intelek-book-a-demo/',

  //NOSOTROS
  '/nosotros/careers-fira/': '/nosotros/forum-fme/',
  // '/nosotros/blog/': '/blog/',
  // '/nosotros/blog-entry/': '/blog-post/',

  //OTHERS
  '/otros/aviso-legal.html': '/legal-notice.html',
  '/otros/terms-and-conditions.html': '/terms-and-conditions.html',
  '/otros/politica-privacidad.html': '/privacy-policy.html',
};
