module.exports = {
  //HOME
  '/': '/',

  //SERVICES
  '/servicios/expert-guidance/': '/servicios-estrategia-analitica/',
  '/servicios/data-preparation/': '/servicios-analitica-datos/',
  '/servicios/data-architecture/': '/servicios-arquitectura-datos/',
  '/servicios/business-intelligence/': '/servicios-visualizacion-datos/',
  '/servicios/artificial-intelligence/': '/consultoria-inteligencia-artificial/',

  //SOLUTIONS
  //Solutions - Por sector
  '/soluciones/industry/': '/soluciones-datos-industria/',
  '/soluciones/retail/': '/soluciones-datos-retail/',
  '/soluciones/insurance/': '/soluciones-datos-seguros/',
  '/soluciones/hospitality/': '/soluciones-datos-hosteleria/',
  '/soluciones/admin/': '/soluciones-datos-administracion/',
  //Solutions - Por tipología
  '/soluciones/strategy/': '/soluciones-datos-analitica/',
  '/soluciones/machine-learning/': '/soluciones-machine-learning/',
  '/soluciones/business-intelligence/': '/soluciones-business-intelligence/',
  '/soluciones/infrastructure/': '/soluciones-infraestructura-datos/',

  //PLATFORM
  '/plataforma/intelek-landing/': '/plataforma-analitica-datos/',
  '/plataforma/collector/': '/software-captura-datos/',
  '/plataforma/compute/': '/software-procesamiento-datos/',
  '/plataforma/analytics/': '/software-business-intelligence/',
  '/plataforma/recommender/': '/soluciones-datos-ventas/',
  '/plataforma/data-governance/': '/software-gobierno-datos/',
  '/plataforma/data-manager/': '/software-gestion-datos/',
  '/plataforma/data-api/': '/software-api-datos/',
  '/plataforma/start-trial/': '/intelek-free-trial/',
  '/plataforma/book-demo-form/': '/intelek-book-a-demo/',

  //NOSOTROS
  '/nosotros/careers-fira/': '/nosotros/forum-fme/',
  // '/nosotros/blog/': '/blog/',
  // '/nosotros/blog-entry/': '/blog-post/',

  //OTHERS
  '/otros/aviso-legal.html': '/aviso-legal.html',
  '/otros/terms-and-conditions.html': '/terminos-y-condiciones.html',
  '/otros/politica-privacidad.html': '/politica-privacidad.html',
};
